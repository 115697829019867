<template>
   <div class="rental-wrap">
      <!-- <navbar /> -->
      <div class="container">
         <div class="row">
            <h4>Rentals</h4>
            <carousel class="carousel-box" :per-page="4" :navigationEnabled="true">
               <slide v-for="rent in rental" :key="rent.ID">
                  <div class="card rental-card" @click="RentalClick(rent.ID)">
                  <img :src="rent.Image" /><br />
                  &nbsp; {{ rent.Name.toUpperCase() }}<br />
                  </div>
               </slide>
            </carousel>
         </div>
      </div>

      <footer-home />
   </div>
</template>
<script>
import { Carousel, Slide } from "vue-carousel";
import FooterHome from "../components/FooterHome.vue";

export default {
   data() {
      return {
         rental: {},
         rental: [
         {
            ID: 0,
            Name: "Car",
            Image: require("../assets/car.jpg"),
         },
         {
            ID: 1,
            Name: "Stretch Limo",
            Image: require("../assets/stretchlimo.jpg"),
         },
         {
            ID: 2,
            Name: "Scooba Diving",
            Image: require("../assets/scooba.jpg"),
         },
         {
            ID: 3,
            Name: "Yacht",
            Image: require("../assets/yacht.jpg"),
         },
         ],
      }
   },
   components: {
      Carousel,
      Slide,
      // Navbar,
      FooterHome
   },
   mounted() {

   },
   methods: {
      RentalClick(id) {
         Object.keys(this.rental).forEach((key) => {
         if (id == key) {
            return this.$router.push(
               "/rental/" + this.rental[key].Name.replace(/\s+/, "")
            );
         }
         });
      },
   }

}
</script>
<style lang="scss" scoped>

body {
  font-family: "Open Sans", sans-serif;
}

.rental-wrap {
  height: 100vh!important;
}

h4 {
  margin: 2% 0 0 0;
  text-decoration: underline;
  text-decoration-color: #e4a700;
  text-underline-offset: 5px;
  text-transform: uppercase;
}

img {
  width: 100%;
  height: 180px;
  background-size: cover;
  background-position: center;
  border-radius: 5px;
}

.carousel-box {
  margin-top: 2%;
  margin-bottom: 5%;
  height: fit-content;
}

.rating {
  width: 110px;
  height: 15px;
}

.VueCarousel-inner {
  height: 600px !important;
}

.card {
  border-radius: 4px;
  border: none;
  display: flex;
  // height: 285px !important;
  height: 20rem;
  color: #212121;
  justify-content: space-between;
  margin: 10px 10%;
  // padding: 0 0 10px 0;
  text-align: center;
  -webkit-box-shadow: 0px 3px 9px 0px rgba(220, 220, 220, 1);
  -moz-box-shadow: 0px 3px 9px 0px rgba(220, 220, 220, 1);
  box-shadow: 0px 3px 9px 0px rgba(220, 220, 220, 1);
  cursor: pointer;

  .card-title {
    font-weight: 400;
    font-size: 1rem;
  }
}

.rental-card {
  height: 15rem;
  padding: 0 0 20px 0;
  background: #fafafa;
  color: #212121;
  font-weight: 500;
}

</style>
